let isSpinning = true;

let hologram = new Zdog.Illustration({
  element: '.hologram',
  dragRotate: true,
  onDragStart: function () {
    isSpinning = false;
  },
});

let hologramGroup = new Zdog.Group({
  addTo: hologram
});

// fill
new Zdog.Shape({
  addTo: hologramGroup,
  path: [
    { x: -51, y: -146 },
    {
      bezier: [
        { x: -40, y: -115 },
        { x: -27, y: -115 },
        { x: -19, y: -116 }
      ],
    },
    {
      bezier: [
        { x: -19, y: -116 },
        { x: -5, y: -120 },
        { x: 23, y: -118 }
      ]
    },
    {
      bezier: [
        { x: 23, y: -118 },
        { x: 40, y: -115 },
        { x: 49, y: -143 }
      ]
    },
    {
      bezier: [
        { x: 49, y: -143 },
        { x: 58, y: -145 },
        { x: 65, y: -120 }
      ]
    },
    {
      bezier: [
        { x: 65, y: -120 },
        { x: 77, y: -80 },
        { x: 69, y: -50 }
      ]
    },
    {
      bezier: [
        { x: 69, y: -50 },
        { x: 60, y: -20 },
        { x: 17, y: -13 }
      ]
    },
    {
      bezier: [
        { x: 17, y: -13 },
        { x: 7, y: -10 },
        { x: 7, y: 10 }
      ]
    },
    {
      bezier: [
        { x: 7, y: 10 },
        { x: 7, y: -10 },
        { x: 7, y: 146 }
      ]
    },
    {
      bezier: [
        { x: 7, y: 146 },
        { x: -36, y: 148 },
        { x: -52, y: 100 }
      ]
    },
    {
      bezier: [
        { x: -52, y: 100 },
        { x: -60, y: 73 },
        { x: -47, y: 50 }
      ]
    },
    {
      bezier: [
        { x: -47, y: 50 },
        { x: -37, y: 32 },
        { x: -22, y: 20 }
      ]
    },
    {
      bezier: [
        { x: -22, y: 20 },
        { x: 0, y: 2 },
        { x: -25, y: -14 }
      ]
    },
    {
      bezier: [
        { x: -25, y: -14 },
        { x: -67, y: -32 },
        { x: -71, y: -40 }
      ]
    },
    {
      bezier: [
        { x: -71, y: -40 },
        { x: -89, y: -55 },
        { x: -77, y: -100 }
      ]
    },
    {
      bezier: [
        { x: -77, y: -100 },
        { x: -68, y: -135 },
        { x: -51, y: -146 }
      ]
    },
  ],
  closed: true,
  fill: true,
  color: '#8ae9fd',
  translate: { z: -10 }
});

// bottom 3
new Zdog.Shape({
  addTo: hologramGroup,
  path: [
    { x: 7, y: 120 },
    {
      bezier: [
        { x: -10, y: 115 },
        { x: -42, y: 110 },
        { x: -40, y: 42 }
      ],
    },
    {
      bezier: [
        { x: -42, y: 42 },
        { x: -38, y: 90 },
        { x: 7, y: 104 }
      ],
    },
    {
      bezier: [
        { x: 7, y: 104 },
        { x: 7, y: 115 },
        { x: 7, y: 120 }
      ],
    },
  ],
  closed: true,
  fill: true,
  color: '#1eccf9',
  translate: { z: -6 }
});

// bottom 2
new Zdog.Shape({
  addTo: hologramGroup,
  path: [
    { x: 7, y: 137 },
    {
      bezier: [
        { x: 0, y: 135 },
        { x: -60, y: 110 },
        { x: -40, y: 42 }
      ],
    },
    {
      bezier: [
        { x: -40, y: 42 },
        { x: -50, y: 93 },
        { x: 7, y: 120 }
      ],
    },
    {
      bezier: [
        { x: 7, y: 120 },
        { x: 7, y: 133 },
        { x: 7, y: 137 }
      ],
    },
  ],
  closed: true,
  fill: true,
  color: '#1665B0',
  translate: { z: -3 }
});

// bottom 1
new Zdog.Shape({
  addTo: hologramGroup,
  path: [
    { x: 7, y: 146 },
    {
      bezier: [
        { x: -23, y: 150 },
        { x: -78, y: 110 },
        { x: -40, y: 42 }
      ],
    },
    {
      bezier: [
        { x: -40, y: 42 },
        { x: -62, y: 112 },
        { x: 7, y: 137 }
      ],
    },
    {
      bezier: [
        { x: 7, y: 137 },
        { x: 7, y: 143 },
        { x: 7, y: 146 }
      ],
    },
  ],
  closed: true,
  fill: true,
  color: '#1D235D'
});

// neck 1
new Zdog.Shape({
  addTo: hologramGroup,
  path: [
    { x: 5, y: 19 },
    {
      bezier: [
        { x: 5, y: 19 },
        { x: -4, y: 19 },
        { x: -14, y: 14 }
      ],
    },
    {
      bezier: [
        { x: -14, y: 14 },
        { x: -4, y: 16 },
        { x: 5, y: 13 }
      ],
    },
  ],
  closed: true,
  fill: true,
  color: '#1D235D'
});

// neck 3
new Zdog.Shape({
  addTo: hologramGroup,
  path: [
    { x: 5, y: 12 },
    {
      bezier: [
        { x: 5, y: 12 },
        { x: 0, y: 13 },
        { x: -13, y: 9 }
      ],
    },
    {
      bezier: [
        { x: -7, y: 9 },
        { x: -4, y: 10 },
        { x: 5, y: 6 }
      ],
    },
  ],
  closed: true,
  fill: true,
  color: '#1eccf9',
  translate: { z: -6 }
});

// neck 2
new Zdog.Shape({
  addTo: hologramGroup,
  path: [
    { x: -14, y: 14 },
    {
      bezier: [
        { x: -14, y: 14 },
        { x: -4, y: 15 },
        { x: 5, y: 12 }
      ],
    },
    {
      bezier: [
        { x: 5, y: 12 },
        { x: -4, y: 12 },
        { x: -13, y: 9 }
      ],
    },
  ],
  closed: true,
  fill: true,
  color: '#1665B0',
  translate: { z: -3 }
});

// ear left 2
new Zdog.Shape({
  addTo: hologramGroup,
  path: [
    { x: -42, y: -127 },
    {
      bezier: [
        { x: -74, y: -100 },
        { x: -75, y: -60 },
        { x: -72, y: -47 }
      ],
    },
    {
      bezier: [
        { x: -72, y: -47 },
        { x: -73, y: -90 },
        { x: -33, y: -115 }
      ],
    },
    {
      bezier: [
        { x: -33, y: -115 },
        { x: -40, y: -125 },
        { x: -42, y: -127 }
      ],
    },
  ],
  closed: true,
  fill: true,
  color: '#1eccf9',
  translate: { z: -6 }
});

// ear left 1
new Zdog.Shape({
  addTo: hologramGroup,
  path: [
    { x: -51, y: -146 },
    {
      bezier: [
        { x: -74, y: -130 },
        { x: -86, y: -70 },
        { x: -72, y: -47 }
      ],
    },
    {
      bezier: [
        { x: -72, y: -47 },
        { x: -83, y: -95 },
        { x: -42, y: -127 }
      ],
    },
    {
      bezier: [
        { x: -42, y: -127 },
        { x: -49, y: -135 },
        { x: -51, y: -146 }
      ],
    },
  ],
  closed: true,
  fill: true,
  color: '#1665B0',
  translate: { z: -3 }
});

// ear right 2
new Zdog.Shape({
  addTo: hologramGroup,
  path: [
    { x: 42, y: -127 },
    {
      bezier: [
        { x: 52, y: -125 },
        { x: 67, y: -110 },
        { x: 68, y: -92 }
      ],
    },
    {
      bezier: [
        { x: 68, y: -92 },
        { x: 53, y: -113 },
        { x: 34, y: -119 }
      ],
    },
    {
      bezier: [
        { x: 34, y: -119 },
        { x: 37, y: -125 },
        { x: 42, y: -127 }
      ],
    },
  ],
  closed: false,
  fill: true,
  color: '#1eccf9',
  translate: { z: -6 }
});

// ear right 1
new Zdog.Shape({
  addTo: hologramGroup,
  path: [
    { x: 50, y: -143 },
    {
      bezier: [
        { x: 65, y: -132 },
        { x: 67, y: -110 },
        { x: 68, y: -92 }
      ],
    },
    {
      bezier: [
        { x: 68, y: -92 },
        { x: 60, y: -115 },
        { x: 43, y: -127 }
      ],
    },
    {
      bezier: [
        { x: 43, y: -127 },
        { x: 48, y: -132 },
        { x: 50, y: -143 }
      ],
    },
  ],
  closed: false,
  fill: true,
  color: '#1665B0',
  translate: { z: -3 }
});

// eye left
new Zdog.Shape({
  addTo: hologramGroup,
  path: [
    { x: -3, y: -57 },
    {
      bezier: [
        { x: -1, y: -58 },
        { x: -5, y: -86 },
        { x: -25, y: -90 }
      ],
    },
    {
      bezier: [
        { x: -25, y: -90 },
        { x: -52, y: -96 },
        { x: -53, y: -70 }
      ],
    },
    {
      bezier: [
        { x: -52, y: -70 },
        { x: -56, y: -56 },
        { x: -34, y: -52 }
      ],
    },
    {
      bezier: [
        { x: -34, y: -52 },
        { x: -8, y: -50 },
        { x: -3, y: -57 }
      ],
    },
  ],
  closed: true,
  fill: true,
  color: '#1D235D',
  translate: { z: 10 }
});

// eye left iris
new Zdog.Shape({
  addTo: hologramGroup,
  path: [
    { x: -3, y: -57 },
    {
      bezier: [
        { x: -1, y: -58 },
        { x: -3, y: -79 },
        { x: -17, y: -73 }
      ],
    },
    {
      bezier: [
        { x: -17, y: -73 },
        { x: -30, y: -67 },
        { x: -21, y: -52 }
      ],
    },
    {
      bezier: [
        { x: -21, y: -52 },
        { x: -8, y: -50 },
        { x: -3, y: -57 }
      ],
    },
  ],
  closed: true,
  fill: true,
  color: '#1eccf9',
  translate: { z: 15 }
});

// eye right
new Zdog.Shape({
  addTo: hologramGroup,
  path: [
    { x: 37, y: -60 },
    {
      bezier: [
        { x: 34, y: -75 },
        { x: 50, y: -90 },
        { x: 42, y: -80 }
      ],
    },
    {
      bezier: [
        { x: 42, y: -80 },
        { x: 48, y: -93 },
        { x: 70, y: -92 }
      ],
    },
    {
      bezier: [
        { x: 70, y: -92 },
        { x: 80, y: -70 },
        { x: 70, y: -55 }
      ],
    },
    {
      bezier: [
        { x: 70, y: -55 },
        { x: 43, y: -52 },
        { x: 37, y: -60 }
      ],
    },
  ],
  closed: true,
  fill: true,
  color: '#1D235D',
  translate: { z: 10 }
});

// eye right iris
new Zdog.Shape({
  addTo: hologramGroup,
  path: [
    { x: 37, y: -60 },
    {
      bezier: [
        { x: 34, y: -77 },
        { x: 49, y: -82 },
        { x: 57, y: -72 }
      ],
    },
    {
      bezier: [
        { x: 57, y: -72 },
        { x: 63, y: -62 },
        { x: 52, y: -56 }
      ],
    },
    {
      bezier: [
        { x: 52, y: -56 },
        { x: 42, y: -53 },
        { x: 37, y: -60 }
      ],
    },
  ],
  closed: true,
  fill: true,
  color: '#1eccf9',
  translate: { z: 15 }
});

// nose
new Zdog.Shape({
  addTo: hologramGroup,
  path: [
    { x: 16, y: -43 },
    {
      bezier: [
        { x: 11, y: -41 },
        { x: 18, y: -35 },
        { x: 21, y: -36 }
      ],
    },
    {
      bezier: [
        { x: 21, y: -36 },
        { x: 24, y: -36 },
        { x: 26, y: -41 }
      ],
    },
    {
      bezier: [
        { x: 26, y: -41 },
        { x: 27, y: -45 },
        { x: 22, y: -44 }
      ],
    },
    {
      bezier: [
        { x: 22, y: -44 },
        { x: 19, y: -44 },
        { x: 16, y: -43 }
      ],
    },
  ],
  closed: true,
  fill: true,
  color: '#1D235D',
  translate: { z: 20 }
});

// contour
new Zdog.Shape({
  addTo: hologramGroup,
  path: [
    { x: -51, y: -146 },
    {
      bezier: [
        { x: -40, y: -115 },
        { x: -27, y: -115 },
        { x: -19, y: -116 }
      ],
    },
    {
      bezier: [
        { x: -19, y: -116 },
        { x: -5, y: -120 },
        { x: 23, y: -118 }
      ]
    },
    {
      bezier: [
        { x: 23, y: -118 },
        { x: 40, y: -115 },
        { x: 49, y: -143 }
      ]
    },
    {
      bezier: [
        { x: 49, y: -143 },
        { x: 58, y: -145 },
        { x: 65, y: -120 }
      ]
    },
    {
      bezier: [
        { x: 65, y: -120 },
        { x: 77, y: -80 },
        { x: 69, y: -50 }
      ]
    },
    {
      bezier: [
        { x: 69, y: -50 },
        { x: 60, y: -20 },
        { x: 17, y: -13 }
      ]
    },
    {
      bezier: [
        { x: 17, y: -13 },
        { x: 7, y: -10 },
        { x: 7, y: 10 }
      ]
    },
    {
      bezier: [
        { x: 7, y: 10 },
        { x: 7, y: -10 },
        { x: 7, y: 146 }
      ]
    },
    {
      bezier: [
        { x: 7, y: 146 },
        { x: -36, y: 148 },
        { x: -52, y: 100 }
      ]
    },
    {
      bezier: [
        { x: -52, y: 100 },
        { x: -60, y: 73 },
        { x: -47, y: 50 }
      ]
    },
    {
      bezier: [
        { x: -47, y: 50 },
        { x: -37, y: 32 },
        { x: -22, y: 20 }
      ]
    },
    {
      bezier: [
        { x: -22, y: 20 },
        { x: 0, y: 2 },
        { x: -25, y: -14 }
      ]
    },
    {
      bezier: [
        { x: -25, y: -14 },
        { x: -67, y: -32 },
        { x: -71, y: -40 }
      ]
    },
    {
      bezier: [
        { x: -71, y: -40 },
        { x: -89, y: -55 },
        { x: -77, y: -100 }
      ]
    },
    {
      bezier: [
        { x: -77, y: -100 },
        { x: -68, y: -135 },
        { x: -51, y: -146 }
      ]
    },
  ],
  closed: true,
  stroke: 7,
  color: '#1D235D'
});

// tail
new Zdog.Shape({
  addTo: hologramGroup,
  path: [
    { x: 7, y: 146 },
    {
      bezier: [
        { x: 25, y: 146 },
        { x: 49, y: 137 },
        { x: 60, y: 100 }
      ],
    },
    {
      bezier: [
        { x: 60, y: 100 },
        { x: 67, y: 80 },
        { x: 55, y: 50 }
      ],
    },
    {
      bezier: [
        { x: 55, y: 50 },
        { x: 51, y: 36 },
        { x: 61, y: 32 }
      ],
    },
    {
      bezier: [
        { x: 61, y: 32 },
        { x: 67, y: 30 },
        { x: 77, y: 31 }
      ],
    },
    {
      bezier: [
        { x: 77, y: 31 },
        { x: 84, y: 28 },
        { x: 78, y: 18 }
      ],
    },
    {
      bezier: [
        { x: 78, y: 18 },
        { x: 71, y: 9 },
        { x: 60, y: 12 }
      ],
    },
    {
      bezier: [
        { x: 60, y: 12 },
        { x: 48, y: 14 },
        { x: 45, y: 30 }
      ],
    },
    {
      bezier: [
        { x: 45, y: 30 },
        { x: 42, y: 40 },
        { x: 52, y: 70 }
      ],
    },
    {
      bezier: [
        { x: 52, y: 70 },
        { x: 56, y: 95 },
        { x: 49, y: 110 }
      ],
    },
    {
      bezier: [
        { x: 49, y: 110 },
        { x: 42, y: 141 },
        { x: 7, y: 141 }
      ],
    },
  ],
  closed: true,
  fill: true,
  stroke: 7,
  color: '#1D235D',
  translate: { x: 4 },
  rotate: { y: 0.7 }
});

hologram.updateRenderGraph();

function animate() {
  if (isSpinning) {
    hologram.rotate.y += 0.02;
  }
  hologram.updateRenderGraph();
  requestAnimationFrame(animate);
}

animate();
